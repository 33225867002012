import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Header from "../layout/header"
import { healthcareIcons, icons, technologyIcons } from "../utils/importIcons"
import axios from "axios"
import { doNothing, sendGAEvent } from "../utils/utilFunctions"
import close from '../assets/media/close.svg'
import download from '../assets/media/download.svg'
import { Helmet } from "react-helmet-async"
import Footer from "../layout/footer"
import CookiesNotice from "../components/cookiesNotice"
import { useCookies } from "react-cookie"
import { baseAPI } from "../utils/urls"
import ReactGA from 'react-ga4'


const IconDetails: React.FC = () => {
    const capitalisePageTitle = (str:string) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const [displayLoader, setDisplayLoader] = useState('hidden')
    const { iconName } = useParams()
    const [userDetails, setUserDetails] = useCookies(['email'])
    const [userStatus, setUserStatus] = useState<any>("")
    const [iconPlan, setIconPlan] = useState("")
    const [freeStatus, setFreeStatus] = useState<boolean>(false)

    interface Icon {
        icon: any;
        iconInner: any;
        name: string;
        width: any;
        downloadURL: string;
        category: string;
        source: string;
    }
    const [renderedIcon, setRenderedIcon] = useState<Icon>({
        icon: '',
        iconInner: '',
        name: '',
        width: '',
        downloadURL: '',
        category: '',
        source: ''
    })
    const [display, setDisplay] = useState<string>('hide')
    const [categoryIcons, setCategoryIcons] = useState<[]>([])
    const [showSimilarIcons, setShowSimilarIcons] = useState({
        message: "Show more",
        display: false
    })

    ReactGA.send({
        hitType: 'pageview',
        page:`/icons/${iconName}`,
        title:`${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon`,
    })

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": `${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon`,
        "image": `${renderedIcon.source}`,
        "description": `Discover our high-quality ${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} icon. Perfect for your projects, the icon adds a professional touch with its sleek design and versatile usability.`,
        "sku": `${renderedIcon.name}-icon`,
        "brand": {
            "@type": "Brand",
            "name": "DeviceIcons"
        },
        "offers": [
            {
            "@type": "Offer",
            "url": "https://deviceicons.com/pricing",
            "priceCurrency": "NGN",
            "price": "0.00",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "description": "Free plan: Includes basic icons with limited customisation."
            },
            {
            "@type": "Offer",
            "url": "https://deviceicons.com/pricing",
            "priceCurrency": "NGN",
            "price": "60000.00",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "description": "Premium plan: Offers premium and industry-specific icons and more customisation options, including different sizes, thickness and colours."
            },
            {
            "@type": "Offer",
            "url": "https://deviceicons.com/pricing",
            "priceCurrency": "NGN",
            "price": "80000.00",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "description": "Pro plan: Offers all premium features along with full customisation and animated icons."
            }
        ]
        
    };


    const getUserStatus = async () => {
        const response = await axios.post(baseAPI+'verify-customer/', {
            email: userDetails.email,
          });
        setUserStatus(response.data)
    }

    
    let foundIcon: any = undefined
    const renderIcon = async () => {
        if (icons.find(icon => icon.includes(`${iconName}.`))){
            foundIcon = icons.find(icon => icon.includes(`${iconName}.`))
            setIconPlan("free");
            setFreeStatus(true);
        } else if (technologyIcons.find(icon => icon.includes(`${iconName}.`))) {
            foundIcon = technologyIcons.find(icon => icon.includes(`${iconName}.`))
            setIconPlan("free");
        } else if (healthcareIcons.find(icon => icon.includes(`${iconName}.`))) {
            foundIcon = healthcareIcons.find(icon => icon.includes(`${iconName}.`))
            setIconPlan("premium");
        } 
    if (foundIcon){
        const response = await axios.get(foundIcon);
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(response.data, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        svgElement.setAttribute('viewBox', `0 0 ${svgElement.getAttribute('width')} ${svgElement.getAttribute('height')}`);
        if (foundIcon.includes('context')){
        svgElement.setAttribute('width', '240');
        svgElement.setAttribute('height', 'auto');
        } else if (foundIcon.includes('desktop') || foundIcon.includes('laptop')){
        svgElement.setAttribute('width', '250');
        svgElement.setAttribute('height', 'auto');
        } else if (foundIcon.includes('phone')){
        svgElement.setAttribute('width', '170');
        svgElement.setAttribute('height', 'auto');
        } else if (foundIcon.includes('tablet')){
        svgElement.setAttribute('width', '220');
        svgElement.setAttribute('height', 'auto');
        } else if (foundIcon.includes('watch')){
            svgElement.setAttribute('width', '200');
            svgElement.setAttribute('height', 'auto');
        } else {
            svgElement.setAttribute('width', '180');
            svgElement.setAttribute('height', 'auto');
        }
        let category = ''
        if (iconName && iconName.includes('phone')) {
            category = 'phone'
        } else if (iconName && iconName.includes('desktop')) {
            category = 'desktop'
        } else if (iconName && iconName.includes('laptop')) {
            category = 'laptop'
        } else if (iconName && iconName.includes('tablet')) {
            category = 'tablet'
        } else if (iconName && iconName.includes('laptop')) {
            category = 'laptop'
        } else if (iconName && iconName.includes('smart-watch')) {
            category = 'smart-watch'
        } else if (iconName && iconName.includes('cloud')) {
            category = 'cloud'
        } else if (iconName && iconName.includes('server')) {
            category = 'server'
        }
        const attribution = document.createComment("© 2024 DeviceIcons. Free for personal and commercial use. Attribution required. License: https://www.deviceicons.com/licence");
        svgElement.append(attribution);
        const svgName = (foundIcon.split('/').pop()!).replace('.svg', '')
        setRenderedIcon((iconData)=>({
            ...iconData,
            icon: svgElement.outerHTML,
            iconInner: svgElement,
            name: svgName.replace(/\..*$/, ''),
            width: svgElement.getAttribute('width'),
            category: category,
            source: foundIcon,
        }))
    }
    }

    useEffect(()=>{
        getUserStatus()
        renderIcon()
    }, [])
    const resizeIcon = (size: string): void => {
        const newResize = renderedIcon.iconInner
        newResize.setAttribute('width', size)
        setRenderedIcon((iconData)=>({
            ...iconData,
            icon: newResize.outerHTML,
            iconInner: newResize
        }))
        
    }

    const hideModal = (): void => {
        setDisplay('hide')
    }

    const setStrokeWidth = (width: string): void => {
        const newStroke = renderedIcon.iconInner
        newStroke.querySelectorAll('path').forEach((element: HTMLElement)=>{
            element.setAttribute('stroke-width', width)
        })
        setRenderedIcon((iconData)=>({
            ...iconData,
            icon: newStroke.outerHTML,
            iconInner: newStroke
        }))
    }

    const downloadIcon = () => {
        sendGAEvent('Button Click', `icon_download_${renderedIcon.name}`, `icon_download_${renderedIcon.name}`)
        if (renderedIcon.icon){
            const blob = new Blob([renderedIcon.icon], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${renderedIcon.name}.svg`;
            a.click()
        }
    }

    const otherIcons = () => {
        if (showSimilarIcons.display === false) {
            setShowSimilarIcons({
                message: "Hide other",
                display: true,
            })
        } else {
            setShowSimilarIcons({
                message: "Show more",
                display: false,
            })
        }
    }

    return(
        <>
        <Helmet>
            {freeStatus 
            ? 
            <title>Free {capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon | DeviceIcons</title>
            :
            <title>{capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon | DeviceIcons</title>
            }
            <meta property="og:title" content={`${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon`}/>
            <meta property="og:image" content={`${renderedIcon.source}`}/>
            <meta name="twitter:title" content={`${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon`}/>
            <meta name="twitter:image" content={`${renderedIcon.source}`}/>
            <meta name="description" content={`Discover our high-quality ${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} icon. Perfect for your projects, the icon adds a professional touch with its sleek design and versatile usability.`}/>
            <meta property="og:description" content={`Discover our high-quality ${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} icon. Perfect for your projects, the icon adds a professional touch with its sleek design and versatile usability.`}/>
            <meta name="twitter:description" content={`Discover our high-quality ${capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} icon. Perfect for your projects, the icon adds a professional touch with its sleek design and versatile usability.`}/>
            <link rel="canonical" href={`https://deviceicons.com/icons/${iconName}`} />
            <script type="application/ld+json">
            {JSON.stringify(schemaData)}
            </script>
        </Helmet>
        <div className="main-body">
        <Header />
        <div className="title-area">
            <div className="page-title">
                {capitalisePageTitle((renderedIcon.name).replace(/-/g, ' '))} Icon
            </div>
        </div>
        <div className="icon-details-page-div">
        <div className={`icon-page-area shadow-xls`}>
            <p className="text-lg">You can scale and thicken the icon, and download the SVG file.</p>
            <br />        
        <div className="w-full sticky top-0 flex cursor-pointer gap-5" style={{marginLeft: "100%"}}>
        </div>
            <br />
            <div className="preview-inner gap-10">
            <div className="icon-details-1">
                { iconPlan === 'free' ?
                <div className="bg-green-200 py-1 px-2 rounded-lg text-center w-fit">Free</div>
                : iconPlan === 'premium' ? 
                <div className="bg-purple-200 py-1 px-2 rounded-lg text-center w-fit">Premium</div>
                :
                doNothing()
                }
            <img src={renderedIcon.source} alt={`${renderedIcon.name.replace(/-/g, ' ')} icon`} className="icon-page-preview" style={{width: renderedIcon.name.includes('context') ? '90%' : renderedIcon.name.includes('desktop') ? '90%' : renderedIcon.name.includes('tablet') ? '70%' : renderedIcon.name.includes('phone') ? '52%' : renderedIcon.name.includes('smart-watch') ? '46%' : '60%'}} />      
            </div>
            <div className="icon-details-2">
                <div className="border-2 rounded-lg p-3">
                <div>
                    <h5 className="text-sm">Category</h5>
                    <h5 className="text-xl">{renderedIcon.category}</h5>
                </div>
                </div>
                <br />
                <button className="py-2 px-6  rounded-full bg-purple-600 text-white flex" onClick={()=>{setDisplay('show')}}>Download</button>
            </div>
            </div>
            <br />
            <hr />
            <br />
        </div>
        <div className="mx-auto w-4/6">
        <button className="bg-gray-200 py-2 px-3" onClick={otherIcons}>{showSimilarIcons.message} {renderedIcon.category} icons?</button>
        </div>
        <div className="icons-area">
        {showSimilarIcons.display && icons
        .filter(item => item.includes(renderedIcon.category))
        .map((icon, index) => 
            <a className="icon-group" href={`${((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')}`} id={(icon.split('/').pop()!).replace('.svg', '')}>
            <div key={index} className="icon" onClick={()=>{}}>
            <img src={icon} loading="lazy" alt={`${(((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')).replace(/-/g, ' ')} icon`} className="icon-set" style={{width: icon.includes('context') ? '80%' : icon.includes('desktop') || icon.includes('laptop') ? '75%' : icon.includes('tablet') ? '60%' : icon.includes('phone') ? '42%' : icon.includes('smart-watch') ? '32%' : '50%'}} />
            </div>
            <span style={{fontSize: '13px'}} className="text-center">{((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')}</span>
            </a>             
         )}
         {showSimilarIcons.display && technologyIcons
        .filter(item => item.includes(renderedIcon.category))
        .map((icon, index) => 
            <a className="icon-group" href={`${((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')}`} id={(icon.split('/').pop()!).replace('.svg', '')}>
            <div key={index} className="icon" onClick={()=>{}}>
            <img src={icon} loading="lazy" alt={`${(((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')).replace(/-/g, ' ')} icon`} className="icon-set" style={{width: icon.includes('context') ? '80%' : icon.includes('desktop') || icon.includes('laptop') ? '75%' : icon.includes('tablet') ? '60%' : icon.includes('phone') ? '42%' : icon.includes('smart-watch') ? '32%' : '50%'}} />
            </div>
            <span style={{fontSize: '13px'}} className="text-center">{((icon.split('/').pop()!).replace('.svg', '')).replace(/\..*$/, '')}</span>
            </a>             
         )}
        </div>
        <div className="mx-auto w-4/6">
        <p className="text-right cursor-pointer text-blue-800" onClick={()=>{sendGAEvent('Button Click', 'see_more_icons', 'see_more_icons'); window.location.href='/icons'}}>Browse All Icons</p>
        </div>
        <br className="h-full"/>

        <div className={`modal-backdrop ${display}`}>
       <div className={`icon-modal shadow-xl`}>
        <div className="w-full sticky top-0 flex gap-5 bg-white rounded-lg p-4">
        <h3 className="text-xl m-2">{renderedIcon.name}</h3> 
        <img src={close} alt="" onClick={hideModal} className="btn-icon absolute right-0 m-5 cursor-pointer"/>
        </div>
            
            <br />
            {
                iconPlan === "free" || userStatus.user_plan === iconPlan 
                ?
                <div className="flex icon-modal-inner gap-1">
                <div className="basis-2/5">
                <div dangerouslySetInnerHTML={{
            __html: renderedIcon.icon || `<img src="${renderedIcon.source}" alt="icon" class="icon" />`,
          }}></div>
                </div>
                <div className="basis=3/5">
                    <div className="border-2 rounded-lg p-3">
                        <h3>Scale</h3>
                        <form className="m-4 border-gray-200 rounded-lg">
                        <input type="radio" name="iconSize" id="small" onChange={()=>{resizeIcon(`${renderedIcon.width/4}`)}}/>
                        <label htmlFor="small" className="radio">
                         Small</label>
                         <input type="radio" name="iconSize" id="medium" onChange={()=>{resizeIcon(`${renderedIcon.width/2}`)}}/>    
                        <label htmlFor="medium" className="radio">
                        Medium</label>
                        <input type="radio" name="iconSize" id="large" onChange={()=>{resizeIcon(`${renderedIcon.width}`)}} defaultChecked/>
                        <label htmlFor="Large" className="radio">
                        Large</label>
                        </form>
    
                    </div>
                    <br />
                    <div className="border-2 rounded-lg p-3">
                        <h3>Stroke Width</h3>
                        <form className="m-4 border-1 border-gray-200 rounded-lg">
                            <input type="radio" name="iconSize" id="light" onChange={()=>{setStrokeWidth('5')}}/>
                            <label htmlFor="light" className="radio">
                                 Light</label>
    
                            <input type="radio" name="iconSize" id="normal" onChange={()=>{setStrokeWidth('10.3')}} defaultChecked/>    
                            <label htmlFor="normal" className="radio">
                                 Medium</label>
    
                            <input type="radio" name="iconSize" id="heavy" onChange={()=>{setStrokeWidth('20')}}/>
                            <label htmlFor="heavy" className="radio">
                                 Heavy</label>
    
                        </form>
                    </div>
                    <br />
                    <div className="icon-buttons">
                    <button className="py-2 px-6  rounded-full bg-purple-600 text-white flex" onClick={downloadIcon}>Download SVG<img src={download} className="btn-icon" alt="" /></button>
                    </div>
                <br />
                <br />
                </div>
                </div>
                : userStatus.user_plan != iconPlan ?
                <div className="px-6 py-8 mx-4 my-3 border rounded-lg">
                    <h3 className="text-2xl">Get the <b className="text-purple-800">{renderedIcon.name}</b> icon with a Premium Plan!</h3>
                    <br />
                    <p className="lg">Subscribe and get access to:</p>
                    <p>- <b>All Premium</b> icons</p>
                    <p>- More icon customisation including <b>colours and extra sizes</b>.</p>
                    <p>- <b>Higher priority support</b></p>
                    <br />
                    <br />
                    <a className="px-5 py-2 bg-purple-700 text-white rounded-lg" href="/pricing">Check out Plans</a>
                </div>
                :
                doNothing()
            }
        </div>
        </div>

        </div>
        </div>
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default IconDetails