import React, { createContext, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import { link } from 'fs';
import Icons from './pages/icons';
import Docs from './pages/docs';
import Pricing from './pages/pricing';
import Login from './pages/login';
import Payment from './pages/payment';
import { verify } from 'crypto';
import { VerifyUser } from './utils/utilFunctions';
import { HelmetProvider } from 'react-helmet-async';
import Licence from './pages/licence';
import ReactGA from 'react-ga4'
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import IconDetails from './pages/iconDetails';
import Articles from './pages/articles';
import ArticlePage from './pages/articlePage';
import TechnologyIconsPage from './pages/technologyIcons';
import IndustryPacks from './pages/industryPacks';
import HealthcareIconsPage from './pages/healthcareIcons';
import PageNotFound from './pages/pageNotFound';
import PlanConfirmation from './pages/planConfirmation';
import Account from './pages/account';
import Category from './pages/category';


const App: React.FC = () => {

  const [item, setItem] = useState<any>('')

  ReactGA.initialize('G-V8Y1H79X0P')

  return (
    <HelmetProvider>
    <Routes>
      <Route element={<Home />} path='/'/>
      <Route element={<Icons />} path='/icons'/>
      <Route element={<IndustryPacks />} path='/industry-packs'/>
      <Route element={<TechnologyIconsPage />} path='packs/technology'/>
      <Route element={<HealthcareIconsPage />} path='packs/healthcare'/>
      <Route element={<IconDetails />} path='/icons/:iconName'/>
      <Route element={<Category />} path='/icons/category/:iconCategory'/>
      <Route element={<Docs />} path='/docs'/>
      <Route element={<Pricing setItem={setItem}/>} path='/pricing'/>
      <Route element={<PlanConfirmation />} path='/plan-confirmation'/>
      <Route element={<Articles/>} path='/articles'/>
      <Route element={<ArticlePage />} path='/articles/:slug'/>
      <Route element={<Login />} path='/login' />
      <Route element={<Account />} path='/account-settings' />
      <Route element={<Payment item={item}/>} path='/payment' />
      <Route element={<Licence />} path='/licence' />
      <Route element={<Terms />} path='/terms-and-conditions' />
      <Route element={<Privacy />} path='/privacy-policy' />
      <Route element={< PageNotFound />} path='*' />
    </Routes>
    </HelmetProvider>
  );
}

export default App;
