import logo from '../assets/media/deviceicons-logo.png'
const Footer: React.FC = () => {
    return(
        <>
        <div className="footer">
            <div className='ft-1 basis-2/4'>
                <img src={logo} alt="" className='w-36' />
                <div className='pl-4'>
                <p className='text-lg text-purple-900 font-bold'>Beautiful Device Icons for Your Projects</p>
                <p>A wide range of icons adjustible to the perfect size and thickness <br /> for your projects</p>
                <br />
                <p className=''>DeviceIcons is a product of <a href="https://seyeogunnowo.com"><b>Seye Ogunnowo</b></a></p>
                </div>
            </div>
            <div className='ft-2'>
            <div className='basis-2/4'>
            <b className=' text-gray-700'>Quick Links</b>
            <br />
            <br />
            <a href="/">Home</a>
            <br />
            <br />
            <a href="/icons">Icons</a>
            <br />
            <br />
            <a href="/pricing">Pricing</a>
            <br />
            <br />
            <a href="">Articles</a>
            </div>
            <div className='ft-3 basis-2/4'>
            <b className='text-gray-700'>Help</b>
            <br />
            <br />
            <a href="/docs">Docs</a>
            <br />
            <br />
            <a href="">FAQ</a>
            <br />
            <br />
            <a href="">Support</a>
            </div>
            <div className='ft-4 basis-2/4'>
            <b className='text-gray-700'>External</b>
            <br />
            <br />
            <a href="https://x.com/DeviceIconsWeb">X</a>
            <br />
            <br />
            <a href="https://www.instagram.com/deviceicons/">Instagram</a>
            <br />
            <br />
            <a href="https://web.facebook.com/profile.php?id=61567025531117">Facebook</a>
            <br />
            <br />
            <a href="https://www.linkedin.com/company/deviceicons">LinkedIn</a>
            <br />
            <br />
            <a href="https://dribbble.com/deviceicons">Dribbble</a>
            </div>
            </div>
        </div>
        <div className='footer-legal'>
        © 2024 DeviceIcons <span className='ml-3'><a href="/licence">Licence</a> · <a href="/terms-and-conditions">Terms and Conditions</a> · <a href="/privacy-policy">Privacy Policy</a></span>
        </div>
        </>
    )
}

export default Footer