import Header from "../layout/header"
import homeBannerBg from '../assets/media/home-banner-bg.jpg'
import frontImage from '../assets/media/deviceicons-front-icon.svg'
import right_arrow from '../assets/media/right-arrow.svg'
import ft1 from '../assets/media/ft1.svg'
import ft2 from '../assets/media/ft2.svg'
import ft3 from '../assets/media/ft3.svg'
import ft4 from '../assets/media/ft4.svg'
import step1 from '../assets/media/step-1.svg'
import step2 from '../assets/media/step-2.svg'
import step3 from '../assets/media/step-3.svg'
import step4 from '../assets/media/step-4.svg'
import Footer from "../layout/footer"
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { shareImage } from "../utils/images"
import { Helmet } from "react-helmet-async"
import ReactGA from 'react-ga4'
import CookiesNotice from "../components/cookiesNotice"
import { sendGAEvent } from "../utils/utilFunctions"




const Home: React.FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page:"/",
        title:"Home",
    })

    const [token, setToken] = useCookies(['user_token'])

    return(
        <>
        <Helmet>
        <title>DeviceIcons</title>
        <meta name="description" content="Explore DeviceIcons, a wide range of versatile icon packs designed for all your device needs. Perfect for web and app developers seeking high-quality icons."/>
        <meta name="keywords" content="device icons, phone icons, tablet icons, desktop icons, laptop icons, icon pack, free icons, premium icons, SVG icons, web development, app development"/>
        <meta name="author" content="DeviceIcons"/>
        <meta property="og:title" content="DeviceIcons"/>
        <meta property="og:description" content="Explore DeviceIcons, a wide range of versatile icon packs designed for all your device needs. Perfect for web and app developers seeking high-quality icons."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://deviceicons.com"/>
        <meta property="og:image" content={shareImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="DeviceIcons"/>
        <meta name="twitter:description" content="Explore DeviceIcons, a wide range of versatile icon packs designed for all your device needs. Perfect for web and app developers seeking high-quality icons."/>
        <meta name="twitter:image" content={shareImage}/>
        <script type="application/ld+json">
        </script>
        </Helmet>

        <Header />
        <div className="main-body">
        <div className="home-banner">
            <img src={frontImage} alt="" className="front-image"/>
            <div className="home-banner-text text-white ml-10 leading-8">
            <h2 className="front-intro">Beautifully Crafted Device Icons <br /> for Your Projects</h2>
            <br />
            <p className="text-xl">Versatile collection of icons for <b>phones</b>, <b>PCs</b>, <b>tablets</b>, <br /> <b>TVs</b>, <b>game consoles</b> and other devices.</p>
            <br />
            <button className="rounded-full bg-yellow-600 py-3 px-5 text-white text-lg flex shadow-lg" onClick={()=>{ sendGAEvent('Button Click', 'explore_icons', 'explore_icons'); window.location.href='/icons'}}>Explore Icons <img src={right_arrow} className="btn-icon fill-white" alt="" /></button>
            </div>
        </div>
        <div className="features-section">
            <div className="feature basis-1/4">
            <img src={ft1} alt="" className="mx-auto w-1/4"/>
            <br />
            <h2 className="text-xl text-purple-900"><b>High Quality Icons</b></h2>
            <br />
            <p className="text-base">Our icons are meticulously crafted to ensure they look great at any size.</p>
            </div>
            <div className="feature basis-1/4">
            <img src={ft2} alt="" className="mx-auto w-2/3"/>
            <br />
            <h2 className="text-xl text-purple-900"><b>Consistent Design Style</b></h2>
            <br />
            <p className="text-base">All icons follow a uniform style for a cohesive look in your projects.</p>
            </div>
            <div className="feature basis-1/4">
            <img src={ft3} alt="" className="mx-auto w-2/4"/>
            <br />
            <h2 className="text-xl text-purple-900"><b>Icons for Every Situation</b></h2>
            <br />
            <p className="text-base">A wide range of icons suited for various situations and themes.</p>
            </div>
            <div className="feature basis-1/4">
            <img src={ft4} alt="" className="mx-auto w-1/5"/>
            <br />
            <h2 className="text-xl text-purple-900"><b>Plug and Play</b></h2>
            <br />
            <p className="text-base">Our icons are easy to add to any project, saving you time and effort.</p>
            </div>
        </div>
        <div className="steps-section">
            <h3 className="text-2xl">Customise and Download</h3>
            <br />
        <div className="steps-inner">
            <div className="step">
            <h2 className="text-xl font-bold tracking-wide">Search</h2>
            <br />
                <img src={step1} className="scale-75 my-auto" alt="" />
            </div>
            <div className="step">
            <h2 className="text-xl font-bold tracking-wide">Scale</h2>
            <br />
                <img src={step2} className="scale-75 my-auto" alt="" />
            </div>
            <div className="step">
            <h2 className="text-xl font-bold tracking-wide">Thicken</h2>
            <br />
                <img src={step3} alt="" className="w-16 mx-auto my-auto" />
            </div>
            <div className="step">
            <h2 className="text-xl font-bold tracking-wide">Download</h2>
            <br />
                <img src={step4} alt="" className="w-20 my-auto mx-auto" />
            </div>

        </div>
        </div>
        </div>
        <CookiesNotice />
        <Footer />
        </>
    )
}

export default Home